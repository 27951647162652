import { defineStore } from "pinia";
import { authDataService } from "@/services/AuthApi";
import { userProfileDataService } from "@/services/UserProfileApi";
import { IUserProfile, IFeatureFlagConfiguration, FeatureFlagState } from "@/models/userProfile.model";

const accessTokenKey = "accessToken";
const accessNonceKey = "acesssNonce";
const featureFlagsKey = 'featureFlags';
const userFullNameKey = "userFullName";
const userPortalLinkKey = "userPortalLink";
const userIdKey = "userId";
const customerIdKey = "customerId";

export const useUserProfileStore = defineStore("userProfile", {
  
  state: () => ({ 
    fullName: localStorage.getItem(userFullNameKey),
    portalLink: localStorage.getItem(userPortalLinkKey),
    accessToken: localStorage.getItem(accessTokenKey),
    accessNonce: localStorage.getItem(accessNonceKey),
    fetureFlags: JSON.parse(localStorage.getItem(featureFlagsKey) || '[]')
   }),
  getters: {
    getFeatureFlag: (state) => (featureName: string) => {
      return state.fetureFlags?.find((f: IFeatureFlagConfiguration) => f.name === featureName);
    },
    hasFeature: (state) => (featureName: string) => {
      featureName = featureName.toLowerCase();
      const feature = state.fetureFlags?.find((f: IFeatureFlagConfiguration) => f.name.toLowerCase() === featureName);
      return feature?.state === FeatureFlagState.enabled;
    }
  },
  actions: {
    setAccessNonce(value: string) {
      let nonce = value?.toLowerCase();
      localStorage.setItem(accessNonceKey, nonce);
      this.accessNonce = nonce;
    },
    async setAccessToken(nonce: string) {
      const token = await authDataService.getInternalToken(nonce);;
      if (token) {
        this.accessToken = token;;
        localStorage.setItem(accessTokenKey, token);;
        this.setAccessNonce(nonce);;
      }
    },
    async setUserProfile(): Promise<IUserProfile | null> {
      const userProfile = await userProfileDataService.getCurrentUserProfile();
      if (userProfile) {
        this.fullName = userProfile.fullName;
        this.portalLink = userProfile.portalLink;
        this.fetureFlags = userProfile.featureFlags;
        localStorage.setItem(userFullNameKey, userProfile.fullName);
        localStorage.setItem(userPortalLinkKey, userProfile.portalLink);
        localStorage.setItem(featureFlagsKey, JSON.stringify(userProfile.featureFlags));
        localStorage.setItem(userIdKey, userProfile.userId);
        localStorage.setItem(customerIdKey, userProfile.customerId);
      }
      return userProfile;
    }
  }
});